.packagePage {
  min-height: calc(100vh - 9rem);
  padding: 32px 32px 3.5rem 32px;
  display: flex;
  flex-direction: column;
  background: rgb(248, 248, 248);
  // justify-content: space-evenly;
  align-items: center;

  .Dropdown {
    &__header,
    &__list-container {
      min-width: 180px;
      width: 250px;
    }
  }
}
.packagePage h1 {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}

.packageCardStage {
  width: 87%;
  @media screen and (max-width: 540px) {
    width: 100%;
  }
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.packageCardStageInner {
  margin-top: 3rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 3rem;
  column-gap: 2rem;
}

.packageCard {
  border-radius: 4px;
  overflow: hidden;
  width: 375px;
  height: auto;
  background: white;
  color: black;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: none;
}
.coloured {
  background: url(../../assets/card.png) no-repeat;
  background-size: cover;
  color: white;
}

.packageCardTop,
.packageCardBottom {
  width: 100%;
}

.packageCardTop ul {
  margin: 32px;
  list-style-type: disc;
  padding-left: 20px;
  @media screen and (max-width: 540px) {
    margin: 25px;
  }
}

.packageCardBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.packageCardImage {
  // min-width: 223px;
  height: 175px;
  // background: #d9d9d9;
  margin-bottom: 0px;
  width: 85%;
  .CardImage {
    width: 100%;
    height: 100%;
  }
}

.packageCardPrice {
  width: 100%;
  background: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.packageCardPageBottom {
  margin-top: 5rem;
  border-radius: 5px;
  @media screen and (max-width: 540px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  background: url(../../assets/packageBottom.png) no-repeat;
  background-size: cover;
  width: 100%;
  color: white;
  padding: 41px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomRight {
  width: 50%;
  display: flex;
  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: space-evenly;

  a span {
    display: block;
    width: 151px;
    height: 49px;
    padding: 15px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
      background: black;
      color: white;
    }
  }
}

.packageCardPageBottom h1 {
  text-align: left;
  margin-bottom: 12px;
  margin-top: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 118.84%;
}

.packageCardPageBottom h3 {
  font-weight: 510;
  font-size: 20px;
  line-height: 118.84%;
}

@media screen and (max-width: 1400px) and (min-width: 1250px) {
  .packageCardStageInner {
    column-gap: 1rem;
  }
}

// @media screen and (max-width: 1250px) {
//   .packageCardStageInner {
//     grid-template-columns: repeat(2, 1fr);
//     column-gap: 0rem;
//   }
// }

@media screen and (max-width: 1400px) {
  .packageCardStageInner {
    flex-direction: column;
    gap: 2rem;
  }
  .packageCard {
    width: 100%;
    height: auto;
    display: flex;
  }
  .packageCardPageBottom {
    flex-direction: column;
    gap: 2rem;
    padding: 41px 0;
  }
  // .packageCardTop h1 {
  //   text-align: left;
  //   padding-left: 35px;
  // }
  .packageCardBottom {
    height: 100%;
    // flex-direction: row;
    justify-content: end;
    gap: 3rem;
  }

  .packageCardTop {
    text-align: left;
    h1 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .packageCardPrice {
    width: 100%;
    height: 60px;
  }
  .packageCardImage {
    margin-bottom: 0;
    min-width: 200px;
    width: 82%;
  }

  .bottomLeft {
    text-align: center;
    h6 {
      display: block;
    }
  }
  .bottomRight {
    width: 80%;
    justify-content: center;
    gap: 2rem;

    .telegram,
    .email {
      display: block;
    }
  }
  .packageCardStageInner {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4rem;
  }
}

@media screen and (max-width: 900px) {
  // .mob-input-pp {
  //   padding-bottom: 30px;
  // }
}
@media screen and (max-width: 767px) {
  // .mob-input-pp {
  //   padding-bottom: 2rem;
  // }
  .packagePage {
    margin-top: -30px;
    padding-top: 32px;
  }
}

@media screen and (max-width: 786px) {
  .packageCard {
    display: flex;
    flex-direction: column;
  }
  .packageCardBottom {
    height: 100%;
    flex-direction: column;
    justify-content: end;
    gap: 2rem;
  }
  .packageCardPrice {
    flex-direction: row;
    width: 100%;
    height: auto;
  }
  .packageCardImage {
    // margin-bottom: 0;
    min-width: unset;
    width: fit-content;
    height: 175px;
  }
  .packageCardStageInner {
    margin-top: 2rem;
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  .bottomLeft {
    width: 80%;
    h1 {
      text-align: center;
      line-height: 35px;
    }

    h6 {
      display: none;
    }
  }

  .bottomRight {
    .telegram,
    .email {
      display: none;
    }
  }
}

@media screen and (max-width: 520px) {
  .packagePage {
    padding: 20px 25px 40px 25px;
    margin-top: -30px;
  }

  .packageCard {
    gap: 4rem;
  }
  .packageCardPageBottom {
    padding-inline: 20px;
    background: url(../../assets/rectangle137.png) no-repeat;
    background-size: cover;
  }
  .packageCardImage {
    min-width: unset;
    width: 85%;
    height: 155px;
  }
  .bottomLeft {
    width: 100%;
    text-align: center;
  }
  .bottomRight {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    a span {
      width: 160px;
    }
  }
}
