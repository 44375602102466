.plContainer {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  padding: 70px 71px;
  font-family: 'Inter', sans-serif;
  min-height: calc(100vh - 9rem);
  background: rgb(248, 248, 248);
  .card {
    background: white !important;
  }

  .CartGrid {
    display: grid;
    margin-top: 40px;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 1180px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 720px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 540px) {
      grid-template-columns: 1fr;
    }
    gap: 1rem;
  }
  @media screen and (max-width: 770px) {
    margin-top: -25px;
    padding-top: 45px !important;
  }
  // padding-bottom: 10px;

  .back {
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;

    span {
      display: inline-block;
      align-items: center;
      vertical-align: middle;
    }

    span:first-child {
      margin-right: 8px;
    }
  }

  h3 {
    margin-bottom: 3rem;
    font-weight: 600;
    @media screen and (max-width: 540px) {
      font-size: 0.9rem;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    max-width: 100%;
    // height: 280px;
    background: white !important;
    width: 90%;
    box-sizing: border-box;
    // background: #f8f8f8;
    border-radius: 3px;

    .headExp {
      // width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 600;
      font-size: larger;
      margin-bottom: 38px;

      .image {
        height: 65px;
        width: 65px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
      }
    }

    .listTit {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    .data {
      display: block;
      font-weight: 400;
      font-size: 15px;
      text-align: justify;
      text-justify: inter-word;
    }

    .price {
      // margin-top: 25px;
      display: flex;
      gap: 13.5px;
      align-items: center;

      .amount {
        font-weight: 600;
        font-size: 21px;
      }

      .plus {
        text-align: center;
        display: inline-block;
        height: 24px;
        width: 24px;
        border: 1px solid black;
        border-radius: 50%;
      }
    }
  }

  .right {
    border-radius: 3px;
    font-size: 16px;
    width: 100%;

    .row {
      border-bottom: 1px solid black;
    }

    .head {
      padding: 15px 0 20px 21px;
      height: 52px;
      display: inline-block;
      width: 227px;
      border-right: 1px solid black;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 18px;
    }
    .lasthead {
      padding: 15px 0 20px 21px;
      height: 52px;
      display: inline-block;
      width: 227px;
      border-right: 1px solid black;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 18px;
    }
    .data {
      padding: 15px 0 20px 39px;
      height: 62px;
      display: inline-block;
      width: 400px;
      box-sizing: border-box;
    }
    .lastdata {
      padding: 15px 0 20px 39px;
      height: 62px;
      display: inline-block;
      width: 400px;
      box-sizing: border-box;
    }

    // th {
    //   padding: 16.2px !important;
    // }
  }

  .cartGrid {
    margin-bottom: 0;
  }

  .martBottom {
    bottom: 0;
  }

  @media screen and (max-width: 720px) {
    .left {
      .data {
        margin-top: 24px;
      }
      .price {
        margin-top: 14px;
      }
    }
  }

  @media screen and (max-width: 1120px) {
    padding: 20px;

    .listTit {
      font-size: 15px !important;
    }
    .data {
      font-size: 13px !important;
    }
    .amount {
      font-size: 16px !important;
    }
    .mainExp {
      flex-direction: column;
    }

    .left {
      width: 95% !important;
      height: auto !important;
    }
    .right {
      width: 100%;
    }
    th {
      font-size: 14px !important;
      font-weight: 500;
    }
    td {
      font-size: 13px !important;
      border: 1.5px solid black !important;
    }
  }

  @media screen and (min-width: 720px) and (max-width: 1120px) {
    .left {
      // width: 95%;

      flex-direction: row !important;
      padding: 0 !important;
      display: flex;
      justify-content: space-between;
      height: auto !important;
      .headExp {
        padding: 0 !important;
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        .listTit {
          display: none;
        }
        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100% !important;
          width: 100% !important;
          border-radius: 0 !important;
        }
      }
      .data {
        margin-top: 0 !important;
        padding: 20px;
        // padding-right: 0;
        width: 80%;

        .listTitR {
          display: block !important;
          font-size: 16px;
          font-weight: 600;
          font-family: 'Inter', sans-serif;
          margin-bottom: 20px;
        }
      }
      .price {
        background-color: black;
        color: white;
        justify-content: center;
        flex-direction: column;
        letter-spacing: 2px;
        margin-top: 0 !important;
        width: 12%;
      }
    }
  }

  @media screen and (min-width: 1284px) {
    .left {
      padding: 30px !important;
    }
  }
  @media screen and (max-width: 901px) {
    .left {
      width: 100% !important;
      margin-bottom: 30px;

      // .headExp {
      //   margin-bottom: 6px;
      // }
    }
  }
}
