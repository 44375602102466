.checkout-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
  text-align: center;
}

.checkout-success__icon {
  color: #4caf50;
  font-size: 4rem;
  margin-bottom: 20px;
}

.checkout-success__title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.checkout-success__message {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.checkout-success__button {
  display: inline-block;
  background-color: #4caf50;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.checkout-success__button:hover {
  background-color: #388e3c;
}
