.signin {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }

  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 9rem);

  .content {
    width: 359px;
    font-family: 'Inter', sans-serif;
    background-color: #f9f9f9;
    padding: 10px 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-bottom: 9px solid black;
    border-radius: 3px;
  }

  .error {
    display: flex;
    align-items: center;
    color: red;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    height: 30px;
    margin-left: 5px;
    width: 100%;
  }

  .chead {
    font-size: 25px;
    font-weight: 600;
    margin-top: 38px;
  }

  .signUp {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  .signUp a {
    text-decoration: none;
    color: #108fb7;
  }

  .email {
    box-sizing: border-box;
    padding: 11px 18px;
    margin-top: 33px;
    width: 251px;
    height: 41px;
  }

  .pass {
    box-sizing: border-box;
    padding: 11px 18px;
    margin-top: 2px;
    width: 251px;
    height: 41px;
  }

  .input-icons {
    position: absolute;
    border: 1px solid red;
  }

  .submitButton {
    margin-top: 26px;
    height: 43px;
    width: 113px;
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 4px;
  }

  .disabled {
    color: black;
    background-color: black;
  }

  .forgot {
    margin-top: 70px;
    margin-bottom: 27px;
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }

  .allRight {
    margin-top: 50px;
    font-size: 15px;
  }

  /* Media query for small screens */
  @media screen and (max-width: 480px) {
    height: 90vh !important;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 85%;
      border-bottom: 3px solid black;
      padding: 10px 25px;

      .chead {
        margin-top: 28px;
      }

      .signUp {
        margin-top: 5px;
      }
    }

    .signin {
      .email {
        margin-top: 37px;
      }
    }

    .pass {
      margin-top: 0;
    }
    .forgot {
      margin-top: 40px;
      margin-bottom: 27px;
      text-align: left;
      font-size: 14px;
      width: 100%;
    }

    .allRight {
      margin-top: 60px;
      margin-bottom: 30px;
      text-align: center;
    }

    .submitButton {
      margin-top: 40px;
      width: 119px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .allRight {
      font-size: 12px !important;
      margin-top: 60px;
    }
  }

  @media screen and (max-width: 480px) {
    height: 80vh;
    justify-content: space-between;
  }

  @media screen and (max-height: 650px) {
    margin-top: 83px;
  }
}
