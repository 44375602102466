.card_Order {
  min-height: calc(100vh - 9rem);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardOrder {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  width: 100%;
  font-family: 'Inter', sans-serif;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  padding: 30px;
  max-width: 1200px;
  max-height: 600px;
  box-sizing: border-box;
  background: #f9f9f9;
  border-radius: 3px;

  margin: 0 auto;

  .headerOrder {
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: bold;
    font-size: large;
  }

  .dataOrder {
    display: block;
    margin-top: 24px;
    padding-right: 60px;
    padding-left: 60px;
    font-weight: 350;
    font-size: 16px;
  }

  @media screen and (max-width: 780px) {
    .card_Order {
      height: auto;
    }

    tr td {
      border: 1px solid black !important;
    }

    .cardOrder {
      background-color: transparent;
      width: 95% !important;
      padding: 10px !important;
    }

    .dataOrder {
      padding: 0 !important;
    }

    .headerOrder {
      margin-top: 7px;
      margin-bottom: 32px;
    }
    .verticalTableId {
      height: 42px;
      width: 115px;
      text-align: left;
      padding: 14px 0 11px 18px;
      font-weight: 600;
      font-size: 15px !important;
      line-height: 18px !important;
    }

    .verticalTableValue {
      font-weight: 400;
      font-size: 15px !important;
      line-height: 18px !important;
    }
  }
}
