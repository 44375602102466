
.popups {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  padding: 20px 40px;
  height: 100%;
  width: 100%;
  background: #f9f9f9;
  font-family: 'Inter', sans-serif;
  border-bottom: 5px solid black;
  border-radius: 0 0 2px 2px;


  .titles {
    padding: 0;
    font-weight: 600;
    font-size: 28px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;

    .emailButtons {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .ips {
      box-sizing: border-box;
      padding: 11px 19px;
      width: 251px;
      height: 41px;
      border-radius: 3px;
      border: 2px solid #000000;
    }

    input:focus {
      outline: 1px solid black;
    }

    .ip1 {
      margin-top: 20px;
    }

  }

  .submits {
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    width: 113px;
    height: 43px;
    background-color: black;
    color: white;
    border: none;
    margin-top: 25px;
    margin-bottom: 45px;
  }

  .twoButton{
    gap: 25px;
  }


    .lastLine{
       width: 90%;
       font-size: 15px;
       font-weight: 500;
       font-family: 'Inter',sans-serif;
    
    }

    a {
      text-decoration: none;
      color: #108fb7;
    }

  @media screen and (max-width: 480px) {
      font-family: 'Inter', sans-serif;
      padding: 10px 15px !important;
      background: #f9f9f9;

        .titles {
          padding: 0;
          font-weight: 600;
          font-size: 20px;
        }
        .paragraph {
          // width: 90%;
          border: 1px solid red;
        }
        .inputs {
          margin-top: 15px;

          .ips {
            box-sizing: border-box;
            padding: 11px 15px;
            width: 100%;
            height: 41px;
            border-radius: 3px;
            border: 1px solid #000000;
          }

          .ip1 {
            margin-top: 20px;
          }

          
        }

        .submits {
          font-weight: 700;
          font-size: 16px;
          text-align: center;
          width: 110px;
          height: 40px;
          background-color: black;
          color: white;
          border: none;
          margin-top: 25px;
          margin-bottom: 40px;
          border-radius: 5px;
        }

        .twoButton{
          justify-content: space-between;
        }
        .lastLine{
          font-size: 12px !important;
          font-weight: 400;
        }      
  }
}

@media screen and (max-width:480px) {
  .pop{
    padding: 10px !important;
  }
}
