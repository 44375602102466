.accountDetails {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  margin-left: 40px;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 40px 40px 50px 40px;
  border-radius: 5px solid black;
  height: max-content;
  width: 90%;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  border-bottom: 5px solid black;
  border-radius: 3px;

  .input {
    font-size: 15px;
    width: 100%;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    background-color: white;

    .round {
      width: 1.1em;
      border: 1px solid black;
      cursor: pointer;
    }
  }

  input[name='account'] {
    accent-color: black;
  }

  .head {
    font-size: 22px;
    font-weight: bold;
  }

  .status {
    font-weight: 500;
    font-size: 14px;
    margin-top: 0px;

    .status {
      color: #108fb7;
    }
  }

  .label {
    font-weight: 500;
  }

  @media screen and (max-width:700px) {
    
      margin-left: 0 !important;
      width: 100% !important;
      padding: 28px 25px;
  
  }

  @media screen and (min-width:700px) and (max-width:968px) {
    margin-left: 0;
    width: 100% !important;
    // .input{
    //   width:60% ;
    // }

  }

  @media screen and (max-width:1380px) and (min-width:968px) {
    margin-left: 0;
    width: 100% !important;
  }

  @media screen and (min-width:1420px)  and (max-width:2000px){
    .input{
      width: 90%;
    }
  }
  
}


