.withdrawlOption {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  font-family: 'Inter', sans-serif;

  width: 77%;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 40px 40px 50px 40px;
  border-radius: 5px solid black;
  height: max-content;
  box-sizing: border-box;

  .input {
    font-size: 15px;
    width: 251px;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    background-color: white;
  }

  .checkBox {
    font-size: 15px;
    width: 100%;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    background-color: white;

    .round {
      width: 1.1em;
      border: 1px solid black;
      cursor: pointer;
    }
  }

  input[name='account'] {
    accent-color: black;
  }

  .head {
    font-size: 20px;
    font-weight: bold;
  }

  .status {
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;

    .status {
      color: #108fb7;
    }
  }

  .label {
    font-weight: 500;
  }

  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center; /* add this line */
    margin-top: 26px;
    height: 43px;
    width: 113px;
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 4px;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #ecea;
    background-color: #eceaea;
    color: #7b7b7b;
  }

  .submitButton svg {
    margin-left: 5px;
  }
  .pBottom {
    color: #585656;
    margin-top: 20px;
    width: 60%;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
  }

  @media screen and (max-width: 480px) {
    margin-left: 0 !important;
    width: 100% !important;
    padding: 28px 25px;

    .css-1v5if98-MuiGrid-root {
      width: 100% !important;
    }
    .head {
      font-size: 22px;
      font-weight: bold;
    }
    .pBottom {
      width: 100%;
      font-size: 12px;
    }
    .status {
      margin-top: 13px !important;
    }
  }

  @media screen and (min-width: 480px) and (max-width: 1200px) {
    width: 100%;

    .head {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
