.identificationDetails {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }

  background: #f9f9f9;
  border-radius: 3px;
  padding: 40px 40px 50px 40px;
  border-radius: 5px solid black;
  height: max-content;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;

  .input {
    font-size: 15px;
    // width: 251px;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    background-color: white;
  }

  .checkBox {
    font-size: 15px;
    width: 100%;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    background-color: white;

    .round {
      width: 1.1em;
      border: 1px solid black;
      cursor: pointer;
    }
  }

  input[name='representCompany'] {
    accent-color: black;
  }

  .head {
    font-size: 22px;
    font-weight: bold;
  }

  .status {
    font-weight: 500;
    font-size: 14px;
    margin-top: 35px;
    color: #3a3838;

    .status {
      color: #108fb7;
    }
  }

  .label {
    font-weight: 500;
    font-size: 15px;
    width: 60%;
  }

  .submitButton {
    margin-top: 26px;
    height: 43px;
    width: 113px;
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 4px;
  }

  button:disabled,
  button[disabled] {
    border: 2px solid #ecea;
    background-color: #eceaea;
    color: #7b7b7b;
  }

  .pBottom {
    // color: #929292;
    margin-top: 50px;
    width: 60%;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
  }
  @media screen and (min-width:520px) and (max-width:920px) {
    .input{
      width: 100% !important;
    }
  }

  @media screen and (min-width:1200px) {
    .checkBox{
      width: 90% !important;
    }
    .input{
      width: 90% !important;
    }
  }

  @media screen and (max-width: 520px) {
 
    margin-left: 0 !important;
    width: 100%;
    padding: 28px 25px;

    .checkBox{
      .label{
        font-size: 14px !important;
      }
    }
  
    .input {
    width: 100% !important;
    }

    .pBottom {
    width: 100% !important;
    }

    .status {
    margin-top: 13px !important;
    }

    @media screen and (max-width:378px) {
      .checkBox{
        .label{
          font-size: 13px !important;
        }
      }
    }

}


}


