.Header {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }

  width: 100%;
  height: 65px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
  @media screen and (min-width: 900px) {
    padding: 12px 35px;
  }
  font-family: 'Inter', sans-serif;

  .left {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .heading {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 33px;
      color: #ffffff;
    }

    .subHeading {
      font-family: 'Inter', sans-serif;
      margin-left: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.5px;
      color: #e9ecef;
    }
  }

  .right {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    gap: 3rem;
    @media screen and (max-width: 818px) {
      font-size: 0.9rem;
      gap: 0.6rem !important;
    }
    @media screen and (max-width: 540px) {
      font-size: 0.7rem;
    }
    // margin-top: 5px;

    align-items: center;

    .myAccount {
      position: relative;

      .dropdown {
        color: white;
        background-color: black;
        position: absolute;
        top: 40px;
        left: -48px;
        display: flex;
        flex-direction: column;
        // gap: 5px;
        width: 180px;
        span {
          padding: 10px;
          text-align: end;
        }
        span:hover {
          background-color: white;
          color: black;
        }
      }
    }

    span:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 540px) {
    .Header {
      height: 74px;
      width: 100%;
      box-sizing: border-box;
      background-color: black;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 0 44px;
      font-family: 'Inter', sans-serif !important;

      .left {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .heading {
          font-size: 27px;
          line-height: 32px;
          font-weight: 600;
        }

        .subHeading {
          margin-left: 13px;
          line-height: 14.5px;
          font-size: 10px;
          font-weight: 400;
          margin-top: -7px;
        }
      }

      .right {
        font-family: 'Inter', sans-serif !important;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        gap: 5px;
        margin-top: 5px;
        align-items: center;

        .myAccount {
          font-family: 'Inter' !important;
          position: relative;

          .dropdown {
            font-family: 'Inter', sans-serif !important;
            color: white;
            background-color: black;
            position: absolute;
            top: 40px;
            left: -48px;
            display: flex;
            flex-direction: column;
            // gap: 5px;
            width: 180px;
            span {
              padding: 10px;
              text-align: end;
            }
            span:hover {
              background-color: white;
              color: black;
            }
          }
        }

        span:hover {
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .left {
      width: 30%;
    }

    .right {
      justify-content: end;
      width: 70%;
      gap: 1.5rem;
    }
  }

  .MuiTypography-body1 {
    font-family: 'Inter', sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.9rem !important;
  }

  .Koin_head {
    font-family: 'Inter', sans-serif !important;
    // font-weight: 800 !important;
  }
}

.getInTouch {
  font-size: 0.9rem !important;
  border: 1px solid white;
  background: white;
  color: black;
  padding: 6px 12px;
  border-radius: 3px;
  margin-left: 12px;
  &:hover {
    background: black;
    color: white;
  }
}

.sign {
  font-family: 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  @media screen and (max-width: 540px) {
    font-size: 0.9rem;
    font-weight: 500;
  }
}
