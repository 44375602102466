.WalletPublisher {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }

  font-family: 'Inter', sans-serif;
  padding: 64px 87px;
  min-height: calc(100vh - 9rem);

  .head {
    display: flex;
    justify-content: space-between;
    background: #f9f9f9;
    border-radius: 3px;
    padding: 15px 37px;
    border-bottom: 6px solid black;
    .left {
      font-size: large;
      font-weight: bold;
    }
    .right {
      color: #4e4d4d;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .content {
    display: flex;
    margin-top: 54px;
    gap: 94px;

    .mainHeading {
      font-weight: 600;
      font-size: 20px;
    }

    .cLeft {
      height: 100%;
      padding: 45px 42px 90px 37px;
      background: #f9f9f9;
      border-radius: 3px;

      .subHeading {
        font-weight: 600;
        font-size: 15px;
        margin-top: 10px;
      }

      .wInput {
        font-size: 15px;
        height: 41px;
        width: 251px;
        margin-top: 28px;
        padding: 11px 18px;
        box-sizing: border-box;
        border: 2px solid black;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
      }

      .wInput label {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input[name='method'] {
        accent-color: black;
      }

      .sub1 {
        margin-top: 32px;
      }

      .method {
        margin-top: 19px;
      }

      .proceed {
        margin-top: 31px;
        color: white;
        font-weight: 700;
        font-size: 16px;
        padding: 12px 16px;
        background: #000000;
        border: none;
        border-radius: 3px;
      }
    }

    .cRight {
      padding: 45px 69px;
      background: #f9f9f9;
      border-radius: 3px;
      flex: 1;

      .walletTable {
        width: 100%;
        border-spacing: 0;
        margin-top: 39px;
        border: 0.5px solid black;
        border-radius: 3px;
        font-size: 16px;

        th {
          font-weight: 600;
        }

        th,
        td {
          padding: 17px 0;
          border: 0.5px solid black;
          text-align: center;
        }
      }
      .walletTableComponent {
        margin-top: 39px;
      }
    }
  }
  @media screen and (max-width: 860px) {
    overflow: hidden;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .head {
      width: 92%;
      display: grid;
      grid-template-columns: 70% 30%;
      justify-content: space-between;
      background: #f9f9f9;
      border-radius: 3px;
      padding: 14px 13px;
      border-bottom: 3px solid black;
      margin-bottom: 0;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        font-weight: 500;
        font-size: 8px;
        line-height: 17px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-top: 47px !important;
      gap: 43px;
      width: 92%;
      margin: auto;
      padding: 0 !important;
      .mainHeading {
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
      }

      .cLeft {
        background: #f9f9f9;
        border-radius: 3px;
        padding: 25px 28px;
        width: 100%;

        .subHeading {
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          width: 100%;
        }

        .wInput {
          font-size: 15px;
          height: 44px;
          width: 280px;
          margin-top: 25px;
          padding: 13px 19px;
          box-sizing: border-box;
          border: 2px solid black;
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
        }
        input[name='method'] {
          accent-color: black;
        }

        .sub1 {
          margin-top: 33px;
        }

        .method {
          margin-top: 19px;
        }

        .proceed {
          margin-top: 43px;
          color: white;
          font-weight: 700;
          font-size: 16px;
          padding: 12px 16px;
          background: #000000;
          border: none;
          border-radius: 3px;
        }
      }

      .cRight {
        padding: 0;
        background: transparent;
        border-radius: 3px;
        flex: 1;
        width: 100%;
      }
    }

    table {
      border-radius: 1em !important;
    }
    .verticalTableId {
      height: 42px;
      width: 115px;
      text-align: left;
      padding: 14px 0 11px 18px;
      font-weight: 600;
      font-size: 15px !important;
      line-height: 18px !important;
    }

    .verticalTableValue {
      font-weight: 400;
      font-size: 15px !important;
      line-height: 18px !important;
    }
  }

  @media screen and (min-width: 481px) and (max-width: 860px) {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 500px !important;
  }

  @media screen and (min-width: 768px) and (max-width: 11180px) {
    padding: 20px;
    .content {
      gap: 20px;
    }
  }
}
