.setpass {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  font-family: 'Inter', sans-serif;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: calc(100vh - 120px);
  min-height: calc(100vh - 9rem);

  input:focus {
    outline: 1px solid black;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .content {
    box-sizing: border-box;
    background-color: #f9f9f9;
    padding: 30px 44px;
    // height: 463px;
    // width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 7px solid black;
    border-radius: 3px;
    margin-top: 10%;

    .error {
      color: red;
      font-size: 13px;
      margin-top: 5px;
      margin-left: 5px;
      width: 254px;
      // accent-color: none;
    }

    .err {
      border: 1px solid red;
    }
    .err:focus {
      outline: none;
    }

    .chead {
      font-size: 25px;
      font-weight: 600;
      // margin-top: 38px;
      margin-bottom: 20px;
    }

    .signUp {
      margin-top: 8px;
      font-size: 14px;
      a {
        text-decoration: none;
        color: #108fb7;
      }
    }

    .password {
      box-sizing: border-box;
      padding: 11px 18px;
      margin-top: 19px;
      width: 251px;
      height: 41px;
    }

    .input-icons {
      position: absolute;
      border: 1px solid red;
    }

    .submitButton {
      margin-top: 26px;
      height: 43px;
      width: 113px;
      background-color: black;
      color: white;
      font-size: 16px;
      font-weight: 700;
      border: none;
      border-radius: 4px;
    }

    .disabled {
      color: black;
      background-color: black;
    }

    .forgot {
      margin-top: 10px;
      margin-bottom: 27px;
      text-decoration: none;
      color: black;
      font-size: 14px;
    }
  }
  .allRight {
    margin-top: 50px;
    font-size: 15px;
  }

  @media screen and (max-width: 540px) {
    height: auto;

    .content {
      padding: 25px 28px !important;
    }

    .password {
      width: 90% !important;
      margin-top: 7px !important;
    }
  }
}
