.Dropdown {
  position: relative;
  // height: 116px;
  display: flex;
  justify-content: start;
  align-items: center;
  @media screen and (max-width: 1180px) {
    width: 90%;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: 540px) {
    width: 100%;
  }

  // margin-bottom: 0px;

  &__header {
    font-size: 15px;
    width: 11rem;

    @media screen and (max-width: 1180px) {
      font-size: 0.8rem;
      width: 100%;
    }
    @media screen and (max-width: 900px) {
      font-size: 0.9rem;
      width: 90%;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
      font-size: 0.8rem;
      font-weight: 500;
    }

    @media screen and (max-width: 320px) {
      font-size: 0.74rem;
    }

    padding: 10px 15px;
    box-sizing: border-box;
    // border: 2px solid black;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    cursor: pointer;
  }

  &__list-container {
    position: absolute;
    top: 105%;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    @media screen and (max-width: 900px) {
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }

  &__list {
    background-color: white;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    @media screen and (max-width: 540px) {
      padding: 0;
    }
    max-height: 200px;
    overflow: auto;
  }

  &__list-option {
    padding: 12px 15px;
    font-size: 0.8rem;
    line-height: 0.9rem;
    // letter-spacing: 0.5px;
    @media screen and (max-width: 540px) {
      font-size: 0.8rem;
    }
    cursor: pointer;

    &:hover {
      background-color: #dbdbdb;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 480px) {
    justify-content: center;
    &__header {
      padding: 9.5px 10px;
    }
    &__header,
    &__list-container {
      width: 100% !important;
    }
  }
}
