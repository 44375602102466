.ppContainer {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  background: rgb(248, 248, 248);
  padding: 70px 71px;
  font-family: 'Inter', sans-serif;
  // padding-bottom: 10px;
  min-height: calc(100vh - 9rem);

  .back {
    font-size: 21px;
    font-weight: 600;
    cursor: pointer;

    span {
      display: inline-block;
      align-items: center;
      vertical-align: middle;
    }

    span:first-child {
      margin-right: 8px;
    }
  }

  // h3 {
  //   margin-bottom: 39px;
  // }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    border-radius: 4px;
    @media screen and (max-width: 540px) {
      padding: 25px;
    }
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border-radius: 3px;

    .headExp {
      // width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      font-weight: 600;
      font-size: larger;
      margin-bottom: 38px;
      &-data {
        display: flex;
      }

      .image {
        height: 65px;
        width: 65px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
      }
    }

    .listTit {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    .data {
      display: block;
      font-weight: 400;
      font-size: 15px;
      text-align: justify;
      text-justify: inter-word;
    }

    .price {
      // margin-top: 25px;
      display: flex;
      gap: 13.5px;
      align-items: center;

      .amount {
        font-weight: 600;
        font-size: 21px;
      }

      .plus {
        text-align: center;
        display: inline-block;
        height: 24px;
        width: 24px;
        border: 1px solid black;
        border-radius: 50%;
      }
    }
  }

  .right {
    border-radius: 3px;
    font-size: 16px;
    width: 100%;

    .row {
      border-bottom: 1px solid black;
    }

    .head {
      padding: 15px 0 20px 21px;
      height: 52px;
      display: inline-block;
      width: 227px;
      border-right: 1px solid black;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 18px;
    }
    .lasthead {
      padding: 15px 0 20px 21px;
      height: 52px;
      display: inline-block;
      width: 227px;
      border-right: 1px solid black;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 18px;
    }
    .data {
      padding: 15px 0 20px 39px;
      height: 62px;
      display: inline-block;
      width: 400px;
      box-sizing: border-box;
    }
    .lastdata {
      padding: 15px 0 20px 39px;
      height: 62px;
      display: inline-block;
      width: 400px;
      box-sizing: border-box;
    }
  }

  .CardGrid {
    margin-bottom: 0;
  }

  .martBottom {
    bottom: 0;
  }

  @media screen and (max-width: 720px) {
    // padding-bottom: 100px;
    .left {
      // .data {
      //   margin-top: 24px;
      // }
      .price {
        margin-top: 14px;
      }
    }
  }

  @media screen and (max-width: 1120px) {
    padding: 20px;
    padding-bottom: 100px;

    .listTit {
      font-size: 15px !important;
    }
    .data {
      font-size: 13px !important;
    }
    .amount {
      font-size: 16px !important;
    }
    .mainExp {
      flex-direction: column;
    }

    .left {
      width: 95% !important;
      height: auto !important;
    }
    .right {
      width: 100%;
    }
    th {
      font-size: 14px !important;
      @media screen and (max-width: 540px) {
        font-size: 0.7rem !important;
        width: auto;
      }
      font-weight: 500;
    }
    td {
      font-size: 13px !important;
      @media screen and (max-width: 540px) {
        font-size: 0.6rem !important;
        width: auto;
      }

      border: 1.5px solid black !important;
      background: white;
    }
  }

  // @media (max-width: 600px) {
  //   th,
  //   td {
  //     display: block;
  //     width: 100% !important;
  //     border: none !important;
  //   }
  //   tr {
  //     border: 1px solid black;
  //     border-radius: 3px;
  //   }
  //   th {
  //     font-size: 0.7rem !important;
  //     font-weight: 700 !important;
  //   }
  //   td {
  //     border-top: 1px solid black !important;
  //   }
  // }

  @media screen and (min-width: 720px) and (max-width: 1120px) {
    .left {
      // width: 95%;
      flex-direction: row !important;
      padding: 0 !important;
      display: flex;
      justify-content: start;
      height: auto !important;
      .headExp {
        padding: 0 !important;
        width: 20%;
        min-height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;

        &-data {
          width: 80%;
          flex-direction: column;
        }
        .listTit {
          display: none;
        }
        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100% !important;
          width: 100% !important;
          border-radius: 0 !important;
        }
      }
      .data {
        margin-top: 0 !important;
        padding: 1rem;
        // padding-right: 0;
        width: 80%;
        display: flex;
        align-items: center;
        gap: 1rem;
        .listTitR {
          display: block !important;
          font-size: 16px;
          font-weight: 600;
          font-family: 'Inter', sans-serif;
          // margin-bottom: 20px;
        }
      }
      .price {
        background-color: black;
        color: white;
        justify-content: center;
        flex-direction: column;
        letter-spacing: 2px;
        margin-top: 0 !important;
        width: 12%;
      }
    }
  }

  @media screen and (min-width: 1284px) {
    .left {
      padding: 30px !important;
    }
  }
  @media screen and (max-width: 901px) {
    .left {
      width: 100% !important;
    }
  }

  .gridPP {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .gridPPinside {
    padding-left: 0 !important;
  }

  @media screen and (min-width: 1120px) {
    .gridPP {
      width: calc(100% + 32px) !important;
      margin-left: -32px !important;
    }
    .gridPPinside {
      padding-left: 32px !important;
    }
  }

  .publisherCardImage {
    height: 220px;
    width: 400px;
  }

  @media screen and (max-width: 1120px) {
    .publisherCardImage {
      height: 150px;
      width: 300px;
    }
  }

  @media screen and (max-width: 480px) {
    .publisherCardImage {
      height: 150px;
      width: 100%;
    }
  }

  @media screen and (max-width: 720px) {
    margin-top: -25px;
  }
}

.ppPrice {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  // padding: 10px;
  margin-top: 10px;

  .topSticky {
    width: 100%;
    @media screen and (max-width: 540px) {
      padding-inline: 0.5rem;
      padding-block: 0.5rem;
    }
    .MuiInputBase-input-MuiOutlinedInput-input {
      padding: 11px !important;
      outline: none;
    }
  }

  p {
    font-size: 13px;
  }
  h2 {
    font-weight: 800;
  }
  .stickyBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    button {
      width: fit-content;
      height: fit-content;
    }
  }
}

.normalCardPrice {
  // padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 1120px) {
  .normalCardPrice {
    padding-inline: 1rem;
    padding-block: 0.8rem;
    // margin-right: 30px;

    @media screen and (max-width: 540px) {
      margin-right: 0;
      width: 100%;
      padding-inline: 0.5rem;
      padding-block: 0.5rem;
    }
  }
}

.continue {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  margin-block: 39px;
  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
}

.NumberTextField {
  margin-bottom: 1rem !important;
  @media screen and (max-width: 540px) {
    margin-bottom: 1.3rem !important;
  }
}
tbody,
table {
  border-radius: 4px;
  overflow: hidden;
}

td {
  background-color: white;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  outline: none;
  border: 2px solid white;
}

strong {
  font-size: 0.9rem;
}

.stickyBottom_left {
  width: 20%;
  display: flex;
  justify-content: start;
  gap: 1rem;
}

@media screen and (max-width: 780px) {
  .telegram-p {
    display: block;
    font-size: 0.9rem;
    text-decoration: underline;
  }
}
@media screen and (min-width: 780px) {
  .telegram-p {
    display: none;
  }
}
