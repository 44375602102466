.footerContainer {
  // margin-top: 120px;
  width: 100%;
  height: 80px;
  padding-inline: 70px;
  background-color: #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;

  .footerLeft {
    width: 40%;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;

      line-height: 18px;
      color: #5f5f5f;
      margin-bottom: 6px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #5f5f5f;
    }
  }

  .footerRight {
    width: 60%;
    display: flex;
    justify-content: end;
    gap: 3rem;
    align-items: center;
    a {
      text-decoration: none;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: right;
      color: #5f5f5f;
      @media screen and (max-width: 1120px) {
        font-size: 0.8rem;
      }
      @media screen and (max-width: 720px) {
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
  }
  @media screen and (max-width: 900px) {
    // margin-top: 20px;
    // height: 100px;
    padding-inline: 20px;
    .footerLeft {
      width: 40%;
    }
    .footerRight {
      width: 70%;
      gap: 2rem;
    }
  }

  @media screen and (max-width: 720px) {
    margin-top: 0;
    padding-block: 30px;
    height: fit-content;
    flex-direction: column;
    padding-inline: 20px;
    gap: 2rem;
    .footerLeft {
      width: 100%;
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 18px;
        text-align: left;
        color: #5f5f5f;
      }
      p {
        font-size: 0.8rem;
      }
    }
    .footerRight {
      width: 100%;
      gap: 1rem;
      flex-direction: column;
      align-items: start;
    }
  }
}
