.ScheduleCallContainer {
  width: 100%;
  height: 200px;
  background: url('../../assets/Rectangle\ 45.png') no-repeat;
  border-radius: 3px; /* adding border radius */
  @media screen and (max-width: 900px) and (min-width: 540px) {
    background: url(../../assets/packageBottom.png) no-repeat;
    background-size: cover;
  }

  @media screen and (max-width: 540px) {
    margin-top: 20px;
    background-size: cover;
  }

  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 25px;
}

.ScheduleCallContainer h1 {
  font-size: 20px;
  @media screen and (max-width: 540px) {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
}

.ScheduleCallContainer a span {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 510;
  font-weight: 510;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: underline;
  color: #ffffff;
}

.sac {
  width: 130px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding: 11px 15px;
  color: black;
  font-weight: 500;
  font-size: 0.85rem;
  text-align: center;

  @media screen and (max-width: 900px) {
    font-size: 0.9rem;
    width: 9rem;
    text-align: center;
  }
  line-height: 17px;
  text-decoration: none;

  &:hover {
    background: black;
    color: white;
    text-decoration: none;
  }
}

@media screen and (max-width: 1359px) {
  .ScheduleCallContainer {
    width: 100%;
    min-height: 220px;
  }
}
