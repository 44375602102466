.resetpopup {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }

  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 9rem);

  .content {
    position: relative; /* Add a relative position to the content container */
    padding: 40px 60px;
    background: #f9f9f9;
    border-radius: 3px;
    border-bottom: 5px solid black;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   bottom: -15px; /* Set the bottom position */
    //   left: 0;
    //   width: 100%;
    //   height: 10px; /* Set the height of the black strip */
    //   background-color: black;
    //   border-radius: 3px 3px 3px 3px; /* Add rounded corners to the bottom of the strip */
    // }

    .error_msg,
    .success_msg {
      width: 251px;
      padding: 15px;
      margin: 5px 0;
      font-size: 14px;
      background-color: #f34646;
      color: white;
      border-radius: 5px;
      text-align: center;
    }

    .success_msg {
      background-color: #5cdd5c;
    }

    .title {
      font-weight: 600;
      font-size: 26px;
    }

    .input {
      margin-top: 35px;

      input:focus {
        outline: 1px solid black;
      }

      .ip {
        box-sizing: border-box;
        padding: 11px 19px;
        width: 251px;
        height: 41px;
        border-radius: 3px;
        border: 2px solid #000000;
      }

      .ip1 {
        margin-top: 20px;
      }
    }

    .submit {
      font-weight: 700;
      font-size: 17px;
      text-align: center;
      width: 113px;
      height: 43px;
      background-color: black;
      color: white;
      border: none;
      margin-top: 25px;
      border-radius: 5px;
    }

    .bottom {
      margin-top: 80px;
      font-size: 15px;
      font-weight: 500;

      a {
        text-decoration: none;
        color: #108fb7;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .content {
      width: 90%;
      padding-left: 25px;
      padding-top: 28px;
      border-bottom: 3px solid black;

      .title {
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
      }

      .input {
        margin-top: 38px;
      }

      .submit {
        margin-top: 43px;
        width: 119px;
        height: 44px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .bottom {
        margin-top: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .resetpopup {
    height: 50vh;
  }
}
