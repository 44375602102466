.uploadDocuments {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  font-family: 'Inter', sans-serif;
  width: 100%;
  background: #f9f9f9;
  border-radius: 3px;
  padding: 40px 40px 50px 40px;
  border-radius: 5px solid black;
  height: max-content;
  box-sizing: border-box;

  .input {
    font-size: 15px;
    width: 251px;
    padding: 11px 18px;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 3px;
    background-color: white;
  }

  .dropdown-container {
    display: inline-block;
    margin-top: 49px;
  }

  .dropdown {
    font-style: normal;
    width: 130%;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    padding: 11px 12px;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 3px;
  }

  // .dropdown {
  //   font-size: 15px;
  //   width: 100%;
  //   padding: 11px 12px;
  //   box-sizing: border-box;
  //   border: 1px solid black;
  //   border-radius: 3px;
  //   display: flex;
  //   justify-content: space-between;
  //   background-color: white;
  // }

  input[name='account'] {
    accent-color: black;
  }

  .head {
    font-size: 18px;
    font-weight: 600; 
    p{
      font-family: 'Inter',sans-serif !important;
    }
  }

  .status {
    color: #3a3838;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;

    .status {
      color: #108fb7;
    }
  }

  .label {
    font-weight: 500;
  }

  .submitButton {
    display: flex;
    align-items: center;
    justify-content: center; /* add this line */
    margin-top: 26px;
    height: 43px;
    width: 113px;
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 4px;
  }

  button:disabled,
  button[disabled] {
    border: 2px solid #ecea;
    background-color: #eceaea;
    color: #7b7b7b;
  }

  .submitButton svg {
    margin-left: 5px;
  }

  .pBottom {
    color: black;
    margin-top: 20px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
  }

  @media screen and (max-width:480px) {
    margin-left: 0 !important;
    width: 100%;
    padding: 28px 25px;


  .head{
    font-size: 22px;
    font-weight: bold;
  }

  .dropdown-container{
    margin-top: 25px;
    width: 100%;
    .dropdown{
      width: 100%;
    }
  }


  .status{
    margin-top: 13px !important;
  }

  .css-2l8bkx-MuiGrid-root{
    margin-top: 25px !important;
  }
}
@media screen and (max-width:768px) {
  
  margin-left: 0 !important;
  width: 100% !important;
  padding: 28px 25px;


  .head{
    font-size: 22px;
    font-weight: bold;
  }

}

@media screen and (min-width:768px) and (max-width:1200px) {
margin-left: 0;
width: 100% !important;


.head{
  font-size: 22px;
  font-weight: bold;
}
}

}
