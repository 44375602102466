$gap: 50px;

.Cart {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  padding: 63px 70px;
  min-height: calc(100vh - 9rem);
  font-family: 'Inter', sans-serif;

  .cart_md {
    font-size: 27px !important;
    padding-top: 20px !important;
  }
  .cartFront {
    width: 65% !important;
  }
  .content {
    display: flex;
    margin-top: 38px;
    gap: $gap;
    border-bottom: none !important;

    .left {
      display: flex;
      flex-direction: column;
      gap: 13px;
      .item {
        overflow: hidden;
        background: #f9f9f9;
        border-radius: 3px;
        height: 60px;
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 20px;

        .itemLeft,
        .itemRight {
          width: 50%;
        }

        .itemLeft {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;
        }

        .itemRight {
          display: flex;
          // justify-content: flex-end;
          align-items: center;
        }

        .image {
          display: inline-block;
          width: 50px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
        }

        .title {
          // display: inline-block;
          width: 82%;
          padding: 20px 24px;
          font-weight: 600;
        }

        .price {
          // display: inline-block;
          text-align: right;
          width: 85%;
          // padding: 20px 35px;
          font-weight: 600;
        }

        .cross {
          width: 15%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .button {
          // display: inline-block;
          width: 5%;
          padding: 20px 35px;
        }
      }
    }

    .right {
      padding: 21px 34px 55px;
      background: #f9f9f9;
      border-radius: 3px;
      flex: 1;
      border-bottom: 4px solid black;

      .title {
        font-weight: 600;
        font-size: 20px;
      }

      .amount {
        margin-top: 12px;
        font-weight: 600;
        font-size: 15px;
        color: #3f3d3d;
      }

      .inputs {
        margin-top: 38px;
        font-weight: 500;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .input {
          padding: 11px 18px;
          display: flex;
          background: #ffffff;
          border: 2px solid black;
          border-radius: 3px;
          justify-content: space-between;
        }

        input[name='paymentMethod'] {
          accent-color: black;
        }
      }

      .placeOrd {
        margin-top: 40px;
        background: #000000;
        border-radius: 3px;
        font-weight: 700;
        font-size: 15px;
        padding: 12px;
        color: white;
        border: none;
      }
    }
  }

  @media screen and (max-width: 1380px) {
    padding: 0 20px;

    .content {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $gap / 1.5;

      .cart {
        font-size: 18px !important;
        margin-bottom: 20px;
      }

      .left {
        .item {
          height: auto;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .title {
            padding: 0;
            width: 75%;
          }

          .price {
            margin-left: 0;
          }
        }
      }

      .right {
        padding: 21px 15px 55px;
      }
    }
  }

  .input {
    align-items: center;
  }
  .ip {
    width: 10%;
  }

  .input_flat {
    display: flex;
    gap: 5px;
  }

  .item {
    justify-content: space-around;
  }
  .title,
  .price {
    font-size: 14px;
    font-weight: 600;
  }

  .image {
    border-radius: 0 !important;
    width: 70px !important;
    height: 70px;
  }

  @media screen and (max-width: 1380px) and (min-width: 600px) {
    padding: 20px;

    .image {
      border-radius: 0 !important;
      width: 70px !important;
      height: 70px;
    }

    .ip {
      width: 5% !important;
    }

    .cartFront {
      width: 100% !important;
    }

    .itemRight {
      height: 100%;
      width: 40% !important;

      .price {
        width: 80% !important;
      }
      .cross {
        width: 20% !important;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .itemLeft {
      width: 60% !important;
      .title {
        width: 60% !important;
      }
    }
    .itemRight {
      height: 100%;
      width: 40% !important;

      .price {
        width: 75% !important;
      }
      .cross {
        width: 25% !important;
      }
    }
  }
}
