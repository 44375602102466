.signup {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  padding: 50px 30px;
  font-family: 'Inter', sans-serif;
  min-height: calc(100vh - 9rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .content {
    width: 660px;
    height: 580px;
    padding: 38px 43px;
    font-family: 'Inter', sans-serif;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-bottom: 9px solid black;
    border-radius: 0 0 3px 3px;

    .heading {
      display: block;
      font-size: 25px;
      font-weight: 600;
    }

    .subHeading {
      display: block;
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      a {
        text-decoration: none;
        color: #108fb7;
      }
    }

    .textIp {
      margin-bottom: 5px;
      margin-top: 31px;
      input:focus {
        outline: 1px solid black;
      }
    }

    .caard {
      p {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
        margin-top: 12px;
        // border: 1px solid red;
      }

      .inputs {
        display: flex;
        gap: 33px;
        margin-top: 12px;

        .col {
          width: 100%;
          display: flex;
          flex-direction: column;

          ::placeholder {
            color: #3d3c3c;
          }

          .error {
            display: flex;
            align-items: center;
            padding-top: 11px;
            font-weight: 500;
            color: red;

            font-size: 11.3px;
            padding-left: 5px;
            width: 100%;
            accent-color: none;
          }
        }

        .input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          height: 43px;
          width: 100%;
          padding: 11px 18px;
          border: 1px solid black;
          border-radius: 3px;

          input[name='account'] {
            accent-color: black;
          }

          .round {
            width: 1em;
            height: 1em;
            border: 1px solid black;
            cursor: pointer;
          }
        }

        .input-text {
          box-sizing: border-box;
          height: 43px;
          width: 100%;
          padding: 11px 18px;
          border: 1px solid black;
          border-radius: 3px;
        }

        .err {
          border: 1px solid red;
        }
        .err:focus {
          outline: none;
        }
      }
    }

    .proceed {
      margin-top: 20px;
      .tNc {
        font-size: 14px;
      }

      .submit {
        margin-top: 20px;
        height: 43px;
        width: 113px;
        background-color: black;
        color: white;
        font-size: 16px;
        font-weight: 700;
        border: none;
        border-radius: 5px;
      }
    }
  }

  .allRight {
    font-size: 14px;
    // margin-top: 100px;
    font-weight: 400;
  }

  @media screen and (max-width: 720px) {
    padding: 30px !important;

    .content {
      width: 100%;
      height: 100%;
      // height: 90%;
      padding-top: 28px;
      font-family: 'Inter', sans-serif;
      background-color: #f9f9f9;
      display: flex;
      flex-direction: column;
      border-bottom: 3px solid black;
      border-radius: 0 0 3px 3px;
      padding-left: 25px;
      padding-right: 27px;
      padding-bottom: 33px;

      .heading {
        font-size: 21px;
        font-weight: 600;
      }

      .subHeading {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 400;
        a {
          text-decoration: none;
          color: #108fb7;
        }
      }

      .textIp {
        margin-bottom: 0px;
        margin-top: 0px;
        input:focus {
          outline: 1px solid black;
        }
      }

      .caard {
        margin-bottom: 15px;

        p {
          font-size: 13px;
          font-weight: 700;
          margin-bottom: 24px;
          margin-top: 1px;
          // border: 1px solid red;
        }

        .m-t-23 {
          margin-top: 15px;
        }

        .inputs {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 15px;
          width: 100%;
          .col {
            display: flex;
            flex-direction: column;

            ::placeholder {
              color: #3d3c3c;
            }

            .error {
              font-size: 12px;
            }
          }

          .input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            height: 43px;
            width: 100%;
            padding: 13px 19px;
            border: 1px solid black;
            border-radius: 3px;

            input[name='account'] {
              accent-color: black;
            }

            .round {
              width: 1em;
              height: 1em;
              border: 1px solid black;
              cursor: pointer;
            }
          }

          .input-text {
            box-sizing: border-box;
            height: 43px;
            width: 100%;
            padding: 0px 18px;
            border: 1px solid black;
            border-radius: 3px;
          }

          .err {
            border: 1px solid red;
          }
          .err:focus {
            outline: none;
          }
        }
      }


      .proceed {
        margin-top: 38px;
        .tNc {
          font-size: 12px;
        }

        .submit {
          margin-top: 20px;
          height: 43px;
          width: 113px;
          background-color: black;
          color: white;
          font-size: 16px;
        }
      }
    }
    .allRight {
      font-size: 12px;
      margin-top: 84px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 720px) {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 920px) and (min-width: 720px) {
    height: 95vh;

    .content {
      padding: 16px 20px;
      width: 98%;
      height: auto;

      .inputs {
        justify-content: space-between;
        flex-direction: row;

        .input {
          width: 100% !important;
        }

        .input-text {
          width: 100% !important;
        }
        .col {
          width: 50% !important;
        }

        .error {
          height: 20px !important;
          padding-top: 12px !important;
          font-size: 12px !important;
        }
      }
    }
  }
}
