.Market {
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  }
  background: rgb(248, 248, 248);
  @media screen and (max-width: 540px) {
    background: white;
    .card {
      background: rgb(248, 248, 248) !important;
    }
  }
  min-height: calc(100vh - 9rem);
  font-family: 'Inter', sans-serif;
  display: flex;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media screen and (max-width: 760px) {
    margin-top: -25px;
    padding-top: 25px;
  }
  &__Left {
    width: 30%;
    padding: 50px 70px 70px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1200px) {
      // width: 40%;
      padding: 35px;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 30px;
      padding-bottom: 0;
    }
    @media screen and (max-width: 780px) {
      padding-top: 0;
    }

    @media screen and (max-width: 540px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }
    &-head {
      width: 100%;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      text-transform: capitalize;
      margin-top: 25px;
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
    &-input {
      position: relative;
      margin-top: 16px;
      @media screen and (max-width: 900px) {
        margin-top: 1rem;
        margin-bottom: 0.6rem;
      }
      margin-bottom: 1rem;
      width: 100%;

      input {
        width: 100%;
        @media screen and (max-width: 540px) {
          width: 100%;
          padding: 12px 40px 12px 12px;
          text-align: left;
          font-size: 0.8rem;
          font-weight: 500;
        }
        @media screen and (max-width: 320px) {
          padding: 11.7px 3px;
          padding-right: 25px;
          font-size: 0.74rem;
        }
        padding: 11px 18px;
        // font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        padding-right: 40px;
        border: 2px solid black;
        border-radius: 3px;

        &::placeholder {
          color: black;
        }
      }
    }
    &-form {
      width: 100%;
      @media screen and (max-width: 540px) {
        display: none;
      }
      &--input {
        font-size: 15px;
        width: 100%;
        margin-top: 25px;
        @media screen and (max-width: 900px) {
          margin-top: 1rem;
        }
        padding: 11px 18px;
        box-sizing: border-box;
        border: 2px solid black;
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
      }
      &--button {
        width: 100%;
        margin-top: 25px;
        @media screen and (max-width: 900px) {
          margin-top: 1rem;
        }
        padding: 11px 18px;
        box-sizing: border-box;
        border: 2px solid black;
        border-radius: 3px;
        background-color: black;
        font-family: 'Inter', sans-serif;
        .label {
          color: white;
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.6px;
          line-height: 18px;
        }
      }
    }

    &-mobileDropdown {
      width: 100%;
      @media screen and (max-width: 540px) {
        margin-bottom: -0.4rem;
      }
      @media screen and (min-width: 540px) {
        display: none;
      }
    }
    .marketScheduleCall {
      width: 100%;
      padding: 1.8rem 0;
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
  }
  &__Right {
    width: 70%;
    display: flex;
    padding: 50px 50px 50px 0px;
    flex-direction: column;
    justify-content: start;
    gap: 2rem;
    @media screen and (max-width: 1370px) {
      row-gap: 1rem;
    }
    @media screen and (max-width: 1200px) {
      // width: 60%;
      padding: 35px;
      padding-left: 0;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
      padding: 30px;
      padding-top: 0;
      padding-bottom: 3rem;
    }
    @media screen and (max-width: 720px) {
      gap: 0.4rem;
    }

    &--filter {
      margin-top: 3.7rem;

      @media screen and (max-width: 900px) {
        margin-top: 1rem;
      }
      display: flex;
      justify-content: start;
      gap: 1.6rem;
      // margin-left: 20px;
      @media screen and (max-width: 1370px) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0;
        margin-left: 0;
        row-gap: 1.4rem;
      }
      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 2rem;
      }
      @media screen and (max-width: 720px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1rem;
        margin-top: 1.5rem;
      }
      @media screen and (max-width: 540px) {
        margin-top: 0rem;
        gap: 0.5rem;
      }
      &-box {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-reset {
        display: flex;
        align-items: end;
        gap: 0.2rem;
        font-size: 1rem;
        color: #4e4d4d;
        // width: 120px;
        // margin-left: rem;
        @media screen and (max-width: 1370px) {
          padding-left: 1rem;
        }
        @media screen and (max-width: 1200px) {
          padding-left: 1rem;
        }
        @media screen and (max-width: 720px) {
          margin-top: 1rem;
          padding-left: 1.2rem;
        }
        @media screen and (max-width: 540px) {
          margin-top: 0.6rem;
          padding-left: 0.6rem;
          font-size: 0.8rem;
        }
      }
    }
    .banner-active {
      margin-top: 1rem;
      @media screen and (max-width: 540px) {
        margin-top: 0;
      }
    }
    &-cardSpace {
      width: 100%;
      margin-top: 1.2rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.6rem;
      @media screen and (max-width: 1180px) {
        margin-top: 1rem;
        gap: 1rem;
      }
      @media screen and (max-width: 900px) {
        gap: 1.4rem;
      }
      @media screen and (max-width: 720px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-pagination {
      padding-block: 1.2rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .mob-Call {
      @media screen and (min-width: 540px) {
        display: none;
      }
      @media screen and (max-width: 540px) {
        display: block;
        margin-top: 1rem;
      }
    }
  }

  .floatIcon {
    display: none;
    flex-direction: column;
    width: fit-content;
    position: fixed;
    bottom: 11%;
    right: 3%;
    gap: 15px !important;
    @media screen and (max-width: 1200px) {
      display: flex;
    }
    @media screen and (max-width: 720px) {
      bottom: 2rem;
      right: 1rem;
    }
    @media screen and (max-width: 540px) {
      bottom: 2.5rem;
      right: 10px;
    }
    .icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: fit-content;
      .ic {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        @media screen and (max-width: 540px) {
          height: 45px;
          width: 45px;
        }
      }
    }
  }
}

.banner {
  height: 99px;
  width: 100%;
  background-color: black;
  border-radius: 3px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    width: 80%;
    color: white;
    text-align: center;
    text-decoration: underline;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 23px;
  }
  @media screen and (max-width: 540px) {
    margin-top: 1rem;
    // margin-bottom: 1rem;
    width: 100%;
  }
  .banner-image {
    height: 100%;
    width: 100%;
  }
}

.SearchIcon {
  @media screen and (max-width: 540px) {
    right: 15px !important;
  }
}

.mobile-schedule-call {
  display: none;
}

@media screen and (max-width: 540px) {
  .mobile-schedule-call {
    display: block;
  }
}
